import React from 'react'
import Layout from '../containers/Layout'
import SEO from '../components/SEO/SEO'
import Hero from '../components/Hero'
import RecentArticles from '../components/RecentArticles'
import Contact from '../components/Contact'

const IndexPage = () => (
    <Layout>
        <Hero />
        <RecentArticles />
        <Contact />
    </Layout>
)

export default IndexPage

export const Head = ({ location }) => {
    return (
        <SEO
            yoastTitle="Rob Marshall | Next JS & Headless WordPress Developer Freelance Leeds UK"
            keywords={[
                'html5',
                'sass',
                'react',
                'wordpress',
                'restapi',
                'node',
                'npm',
                'netlify',
                'nextjs',
                'graphql',
                'es6',
                'developer',
                'software engineer',
                'freelance',
            ]}
            url={location.pathname}
        />
    )
}
